<template>
  <div class="container">
    <h3>请先升级到IE11, 或者使用Edge、Chrome获得更好体验</h3>
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    };
  }
};
</script>
<style scoped>
.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
}
h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
}
</style>